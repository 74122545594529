
.trackingCenterTop {
    display: flex;
    flex-direction: column;
}

.trackingCenterTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-box-shadow: 0px 0px 10px 3px #BABABA; 
    box-shadow: 0px 0px 15px 1px #BABABA;
    border-radius: 10px;
    margin-bottom: 20px;
}

.purpleRow {
    display: flex;
    flex-direction: row;
    padding-right: 125px;
    padding-left: 125px;
    padding-bottom: 10px;
}

.purpleLogo {
    flex: 1;
}

.gtmsLogo2 {
    flex: 1;
    display: flex;
    justify-content: right;
    align-items: center;
}

.trackingCenterGTMSPower {
    padding-right: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size:x-small;
    font-weight: bold;
}

.gtmsimg {
    width: 10%;
    height: 50%;
}

.gtmsLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.trackingCenterRow1 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-left: 100px;
    padding-right: 100px;
}

.trackingCenterFilter {
    flex: 1;
    -webkit-box-shadow: 0px 0px 10px 3px #BABABA; 
    box-shadow: 0px 0px 5px 1px #BABABA;
    border-radius: 5px;
    background-color: #Fffaf1;
}

.trackingCenterSearchBar {
    flex: 3;
    -webkit-box-shadow: 0px 0px 10px 3px #BABABA; 
    box-shadow: 0px 0px 10px 2px #BABABA;
    border-radius: 5px;
}

.TrackingCentereDetailTitle {
    font-family: Arial, Helvetica, sans-serif;
}

.trackingCenterRow2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.trackingCenterThirdScreenWidth {
    display: flex;
    width: 66%;
    margin: 0 auto;
    justify-content: center;
    flex-direction: column;
}

.trackingCenterDetails {
    width: 100%;
  }

.trackingCenterSubmit {
    padding-top: 20px;
    padding-bottom: 20px;
}

.trackingPortalButton {
    padding-top: 20px;
}

.downloadTracking {
    display: flex;
    justify-content: right;
    padding-top: 20px
}

.trackingPortalButton {
    display: flex;
    justify-content: right;
}

.trackingCenterLogos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.trackingCenterLogoPhrase {
    padding-right: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}