.csvBookingMid {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 98vh;
}

.csvBookingForm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 650px;
    height: 600px;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
}

.csvBookingFormTop,
.csvBookingFormMid,
.csvBookingFormBottom {
  flex: 1;
  width: 100%;
}

.csvBookingFormTop {
    flex: 15%;
}

.formTitle {
    display: flex;
    justify-content: center;
}

.csvBookingFormMid {
    flex: 70%;
    display: flex;
    flex-direction: column;
}

.csvBookingChoosingForm {
    flex: 1;
}

.formRow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.csvFormColumn {
    flex: 1;
}

.csvBookingSelect {
    width: 25%;
} 

.csvBookingFormSubmit {
    flex: 3;
    display: flex;
    width: 100%;
}

.csvBookingDropzone {
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
}

.csvBookingFormBottom {
    flex: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.innerFormRow {
    display: flex;
    align-items: center; 
    justify-content: center;
  }

  .csvBookingFormLabel {
    display: flex;
    text-align: right;
    padding-right: 3%;
  }