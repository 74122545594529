.loadQuoteMid {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
}

.loadQuoteForm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 650px;
    height: 600px;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
}

.loadQuoteFormTop, 
.loadQuoteFormMid, 
.loadQuoteFormBottom {
    flex: 1;
    width: 100%;
}

.loadQuoteFormTop {
    flex: 15%;
}

.helpDocument {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadQuoteFormMid {
    flex: 70%;
    display: flex;
    flex-direction: column;
}

.loadQuoteKeyField {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.flex-container {
    display: flex;
    justify-content: center; 
    align-items: center; 
  }

.loadQuoteCostTable {
    max-height: 200px; 
    overflow-y: auto;
}

.loadQuoteFileDrop {
    flex: 3;
    display: flex;
    width: 100%;
}

.loadQuoteDropbox {
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
}

.loadQuoteFormBottom {
    flex: 15%;
}

.loadQuoteKeyField {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.loadQuoteInput {
    display: flex;
    align-items: center;
}

.loadQuoteSubmit {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}