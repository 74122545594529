.resetPasswordPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 98vh;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}

.resetPasswordContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: 500px;
    flex-direction: column;
    overflow: hidden;
    background-color: white;
    -webkit-box-shadow: 0px 0px 27px -2px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 27px -2px rgba(0,0,0,1);
    box-shadow: 0px 0px 27px -2px rgba(0,0,0,1);
}

.resetPasswordTitle {
    font-family: system-ui;
}

.resetPasswordTextFields {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.resetPasswordPassword {
    padding-right: 5px;
}

.resetPasswordConfirmPassword {
    padding-left: 5px;
}

.resetPasswordButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.resetPasswordSubmit {
    padding-right: 5px;
}

.resetPasswordBack {
    padding-left: 5px;
}

.resetPasswordButtons {
    margin: 20px;
}