.loginPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 98vh;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-image: url('../../../assets/logos/CP-Front-Page.jpg');
}

.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 900px;
    height: 600px;
    flex-direction: column;
    overflow: hidden;
    background-color: white;
    -webkit-box-shadow: 0px 0px 27px -2px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 27px -2px rgba(0,0,0,1);
    box-shadow: 0px 0px 27px -2px rgba(0,0,0,1);
}

.loginTitle {
    text-align: center;
    font-family: system-ui;
    font-size: 30px
}

.loginMid {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 33%;
}

.loginUsername {
    padding-top: 25px;
}

.loginPassword {
    padding-top: 10px;
    padding-bottom: 10px;
}

.loginpassword {
    padding: 5px;
    margin: 5px;
}

.loginBottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loginAuthOptions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.loginButton {
    padding-bottom: 10px;
}

.loginRegister {
    padding-top: 5px;
    padding-right: 5px;
}

.forgotPassword {
    padding-top: 5px;
    padding-left: 5px;
}

.forgotPasswordLink {
    text-decoration: none;
    color: #0096FF;
}

.signUpLink {
    text-decoration: none;
    color: #0096FF;;
}