
.DomesticRequestingCompany {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.RequestingCompanyFormContainer {
    width: 33%;
}

.RequestingCompanyDomField {
    padding: 5px;
}


.OriginInformationField {
    padding: 5px;
}

.OriginInformationFormFieldsStep2 {
    display: flex;
}

.DomesticInformationColumn {
    flex: 1;
}


.OriginLocationFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.OriginLocationFormFields {
    width: 33%;
}

.OriginLocationField {
    padding: 5px;
}

.AccessorialsDomesticFormContainer  {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PoDetailsCol {
    display: flex;
    align-items: center;
    flex: 1;
  }

.PoDetailsField {
    width: 100%;
    padding: 5px;
}

.poMessage {
    display: flex;
    justify-content: center;
}

.poDetailsMessage {
    margin: '0 auto';
    color: #1976D2;
    font-weight: bold;
}