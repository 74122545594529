.centerTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-box-shadow: 0px 0px 10px 3px #BABABA; 
    box-shadow: 0px 0px 15px 1px #BABABA;
    border-radius: 10px;
}

.customTitleRow {
    display: flex;
    flex-direction: row;
    margin-top: 0;
    padding-right: 125px;
    padding-left: 125px;
    font-family: Arial, Helvetica, sans-serif;
    min-height: 100px;
}

.customerLogo {
    flex: 1;
    display: flex;
    align-items: center;
}

.customTitlepowerLogo {
    flex: 1;
    display: flex;
    justify-content: right;
    align-items: center;
}

.customTitlegtmsPower {
    font-family: Arial, Helvetica, sans-serif;
    padding-right: 5px;
    font-size:x-small;
    font-weight: bold;
}