.gtmsInboundDomesticPageContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    padding-top: 80px;
  }
  
.gtmsInboundDomesticForm {
    border: 1px solid gray;
    padding: 20px;
}

.gtmsInboundDomesticFormTop {
    position: relative;
    display: flex;
    flex-direction: column;
}

.gtmsInboundDomesticLogo {
    position: absolute;
    top: 0;
    left: 0;
}

.gtmsInboundDomesticSubmit {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}