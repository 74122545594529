.registerPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 98vh;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}

.registerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: 500px;
    flex-direction: column;
    overflow: hidden;
    background-color: white;
    -webkit-box-shadow: 0px 0px 27px -2px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 27px -2px rgba(0,0,0,1);
    box-shadow: 0px 0px 27px -2px rgba(0,0,0,1);
}

.registerMid {
    width: 450px
}

.registerTitle {
    font-family: system-ui;
}

.registerRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;;
    column-gap: 20px;
    padding-top: 20px;
}

.registerBottom {
    height: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.registerSubmit {
    padding-right: 10px;
}

.registerBack {
    padding-left: 10px;
}

.registerRow2 {
    display: flex;
    align-items: center;
    justify-content: flex-start; 
    column-gap: 20px;
    padding-top: 20px;
}

.registerCompany {
    flex: 0 0 auto;
}
  
.registerAdmin {
    display: flex;
    align-items: center;
}

.vendorField {
    display: flex; 
    align-items: center; 
  }