.filter-box{
    display: flex;
    flex-direction: column;
}

.filter-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 10px;
}

.operator-field-select {
    width: 100%;
}

.filter-field-select {
    width: 100%;
}

.value-text-select {
    width: 100%;
}

.minus-filter-select {
    display: flex;
    justify-content: center;
    align-items: center;
}


.right-align {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  
  
  .left-align {
    display: flex;
    justify-content: flex-start; 
    width: 100%;
  }
  