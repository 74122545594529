
.fileHeader {
    text-align: left;
    color: black;
}

  .dropzone {
    position: relative;
    text-align: center;
    color: black;
    border-radius: 5px;
    background-color: #b4dcfc;
    border: 1px solid rgb(209, 209, 209);
    width: 100%;
    height: 100%;
    max-height: 250px;
    overflow: auto; /* Hide overflowing content */
}

.file {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    color: black;
}


.fileRow {
    display: flex;
    align-items: center;
}