.PageContainer {
    display: flex;
  }

.sidebar {
    z-index: 1;
}

.navbar {
    z-index: 2;
}

.auditCenterPageContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 80px;
    height: 100vh;
}

.auditCenterPageContentTop {
    flex: auto;
    margin-bottom: 10px;
}

.auditCenterPageContentMid {
    flex: 100;
    display: flex;
    flex-direction: column;
}

.auditCenterWidgets {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.auditCenterBarGraph {
    height: 66%;
    padding-top: 20px;
}

.auditCenterChart {
    height: 100%;
    -webkit-box-shadow: 0px 0px 5px 3px #BABABA; 
    box-shadow: 0px 0px 5px 1px #BABABA;
    border-radius: 5px;
    padding-bottom: 75px;
}

.auditChartTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
}

.auditCenterButtons {
    display: flex;
    flex-direction: row;
}

.auditCenterToggles {
    flex: 1;
}

.auditCenterDownload {
    flex: 1;
    display: flex;
    justify-content: right;
    align-items: end;
    padding-right: 10px;
}

.centerProgress {
    display: flex;
    justify-content: center;
    align-items: center;
}