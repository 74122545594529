.button-container {
    display: flex;
    justify-content: flex-end;
    gap: 5px; 
    width: 100%;
  }
  
  .button-wrapper {
    display: flex;
    align-items: center;
  }
  
  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px; /* adjust size as needed */
    background-color: white;
    box-shadow: 24;
    padding: 16px;
    border-radius: 5px;
  }