.forgotPasswordPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 98vh;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}

.forgotPasswordContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: 500px;
    flex-direction: column;
    overflow: hidden;
    background-color: white;
    -webkit-box-shadow: 0px 0px 27px -2px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 27px -2px rgba(0,0,0,1);
    box-shadow: 0px 0px 27px -2px rgba(0,0,0,1);
}

.forgotPasswordTop {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.forgotPasswordTitle {
    font-family: system-ui;
}

.restPasswordDescription {
    margin-left: 100px;
    margin-right: 100px;
}

.forgotPasswordButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.forgotPasswordButtons {
    margin: 20px;
}

.forgotPasswordSubmitButton {
    padding-right: 5px;
}

.forgotPasswordback {
    padding-left: 5px;
}