.tnCostBreakdownPageContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 80px;
    align-items: center;
  }

  .sidebar {
    z-index: 1;
  }

  .navbar {
    z-index: 2;
  }

  .tnCostBreakdownMid {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .tnCostBreakdownTitle {
    display: flex;
    justify-content: center;
  }

  .CostBreakdownSearchControls {
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;
    gap: 20px;
  }

  .tnCostBreakdownSearch {
    width: 70%;
  }

  .tnCostBreakdownTable {
    height: 66%;
  }