  .trackingMapPageContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    padding-top: 80px;
  }

  .sidebar {
    z-index: 1;
  }

  .navbar {
    z-index: 2;
  }

  .trackingMapMid {
    -webkit-box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.5);
  }

  .trackingMapTop {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 10px 3px #BABABA; 
    box-shadow: 0px 0px 15px 1px #BABABA;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .trackingMapTitle {
    font-family: Arial, Helvetica, sans-serif;
  }

  h3 {
    font-family: Arial, Helvetica, sans-serif;
  }

  .trackingMapBottom {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    margin-top: 10px;
  }

  .trackingMapBotLeft {
    flex: 1;
    margin: 5px;
    padding: 5px;
    box-shadow: 0px 0px 15px 1px #BABABA;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .trackingMapBotRight {
    flex: 2;
    margin: 5px;
    padding: 5px;
    box-shadow: 0px 0px 15px 1px #BABABA;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .MapSelectControls {
    display: flex;
    flex-direction: row;
  }

  .MapSelectControls {
    justify-content: center;
    align-items: center;
  }