.companyInformationPageContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    padding-top: 80px;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
}

.companyInformationField {
    margin-top: 10px;
    margin-bottom: 10px;
  }

.companyInformationSelect {
grid-column: 1;
justify-self: center;
}

.companyInformationSubmit {
grid-column: 2;
margin-top: 10px;
align-self: center; 
}