.loadQuoteAdminContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    padding-top: 80px;
    height: 100%;
}

.loadQuoteTitle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadQuoteSearch {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;
}

.loadQuoteSearch {
    display: flex;
    flex-direction: row;
}

.loadQuoteHR {
    border: 1px solid #1769aa;
}

.loadQuoteFileExplorer {
    padding-top: 10px;
}