.universalChartComponent {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  height: calc(100vh - 100px);
  box-sizing: border-box;
}

.universalChartComponentLower {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 90%;
  width: 100%;
}

.universalChartComponentLeft {
  flex: 5;
  display: flex;
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.chartComponent {
  width: 100%;
  height: 100%;
  box-sizing: border-box; 
} 

.universalChartComponentRight {
  flex: 1;
  margin: 0% 2%;
  display: flex;
  flex-direction: column;
}

.universalChartFilter {
  font-size: 0.75vw;
  padding: 5% 10%;
  margin-bottom: 2%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  flex-grow: 3;
  overflow-y: auto;
}

.universalChartLegend {
  margin-top: 2%;
  flex-grow: 1;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.universalChartReset {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}