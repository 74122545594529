.reportCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
    padding: 16px;
    margin: 10px;
    width: 350px;
    height: 200px;
    -webkit-box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.5);
  }

.reportCardContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}