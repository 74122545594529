.laneviewTrendsPageContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 80px;
    align-items: center;
    width: 100%;
}

.laneviewTrendsMid {
    width: 100%;
}

.lvtreportSpace {
    display: flex;
    flex-direction: column;
    height: 75vh;
    width: 95%;
    -webkit-box-shadow: 0px 0px 5px 1px #BABABA; 
    box-shadow: 0px 0px 2px 1px #BABABA;
  }

.report{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.reportButtons {
    background-color: #F5F5F5;
    -webkit-box-shadow: 0px 0px 5px 1px #BABABA; 
    box-shadow: 0px 0px 2px 1px #BABABA;
}