.adminLandingPageContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    padding-top: 80px;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
}

.adminLandingLinks {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.adminSquareButton {
    background-color: orange;
    width: 150px;
    height: 150px;
}