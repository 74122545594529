.sidebar {
    z-index: 1;
}

.navbar {
    z-index: 2;
}

.formTrackerPageContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    padding-top: 80px;
    height: 100%;
}

.formTrackerTitle {
    font-family: Arial, Helvetica, sans-serif;
}

.formTrackerSearching {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 10px;
}

.formTrackerSelectBox {
    flex: 1;
    box-shadow: 0px 0px 5px 2px #BABABA;
    border-radius: 5px;
}

.formTrackerSearch {
    flex: 2;
    box-shadow: 0px 0px 5px 2px #BABABA;
    border-radius: 5px;
}

.formTrackerSubmitSearch {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
}

.formTrackerSubmitSearch button {
    margin-right: 10px;
}

.formTrackerShipmentBoard {
    display: flex;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0px 0px 5px 1px #BABABA;
    border-radius: 5px;
    flex: 1;
}



.formTrackerToggle {
    padding-top: 20px;
}

.formTrackerRow2 {
    display: flex;
    flex:2;
    height: 450px; 
}

