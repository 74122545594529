.enhancementPageContainer {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding-top: 100px;
    padding-right: 100px;
    padding-bottom: 100px;
    padding-left: 100px;
    align-items: center;
}

.enhancementPageLeft {
    flex: 5;
}

.enhancementPageRight {
    flex: 1;
    border: 1px solid black;
    padding-bottom: 50px;
}

.enhancementPageHeader {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.enhancementPageCategory {
  display: flex;
  flex-direction: row;
  align-items: left;
}

#enhancementPageCategorySelect {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.enhancementPageTextLimit {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.enhancementPageTextLimit p {
    font-size: small;
}

.enhancementPageTextBox {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-right: 100px;
}

#enhancementPageComment {
    min-height: 300px;
    resize: none;
}

.enhancementPageTextLimitCurrent {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.enhancementPageTextLimitCurrent p {
    font-size: smaller;
}

.enhancementPageButtonSection {
  display: flex;
  flex-direction: row;
}

.enhancementPageSubmitButton {
  padding-left: 5px;
  padding-right: 5px;
}

.enhancementPageCancelButton {
  padding-left: 5px;
  padding-right: 5px;
}

.enhancementPageQuickContactHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.enhancementPageQuickContactBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.enhancementPageQuickContactBoxInfo {
   margin:0
}