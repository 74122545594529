.biovationFileSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.biovationUploadBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0 auto 10px auto; 
}

.biovationSelectBox {
  width: 50%; 
  padding-right: 5px;
  padding-top: 10px;
}

.biovationUploadButton {
  width: 50%; 
  padding-top: 10px;
}

.biovationFileButton {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
