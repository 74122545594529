.calendarPage {
    height: 98vh;
}

.calendarPageContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 80px;
    align-items: center;
    width: 100%;
}

.calendar {
    width: 100%;
    height: 100%;
    margin-top: 20px; 
    box-sizing: border-box;
}

.dockApptSubHeader {
    padding-top: 10px;
    display: flex;
    align-items: center; 
    justify-content: space-between; 
    gap: 10px; 
    width: 80%;
  }
  
.deleveryApptLegend {
    flex: 8; 
}

.dockApptSubHeader > div:nth-child(2) {
    flex: 1;
    text-align: center;
    font-weight: bold;
}

.dockApptCountdownTimer {
    font-weight: bold;
    color: red;
}

.dockApptSubHeader > div:nth-child(3) {
    flex: 2; 
    display: flex;
    justify-content: flex-end; 
}