.customizedTitle2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-box-shadow: 0px 0px 10px 3px #BABABA; 
    box-shadow: 0px 0px 15px 1px #BABABA;
    border-radius: 10px;
}

.centerTitle2 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.customerLogo2 {
    flex: 1;
    margin-left: 25px;
}

.logo-container {
    max-height: 80px;
    overflow: hidden;
    border: 1px solid red;
}

.logo-image {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.customTitlepowerLogo2 {
    flex: 1;
    display: flex;
    justify-content: right;
    align-items: center;
    margin-right: 25px;
}

.customTitlegtmsPower2 {
    font-family: Arial, Helvetica, sans-serif;
    padding-right: 5px;
    font-size:x-small;
    font-weight: bold;
}