.widget {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 10px;
    -webkit-box-shadow: 0px 0px 10px 3px #BABABA; 
    box-shadow: 0px 0px 15px 1px #BABABA;
    border-radius: 10px;
    flex-direction: column;
}

.widgetPageContent {
    height: 80px;
    display: flex;
    flex-direction: row;
}

.rightWidget {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 1px solid gray;
    padding-right: 20px;
}

.widgetIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.leftWidget {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.widgetTitle {
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 3px;
    font-size: larger;
    white-space: nowrap; 
    /* font-weight: bold; */
}

.widgetNumber {
    font-size: x-large;
    font-family: Arial, Helvetica, sans-sersif;
    font-weight: bold;
}

.widgetNumber2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.green-text {
    color: green;
  }
  
.red-text {
color: red;
}
.widgetMid  {
    /* width: 500px;
    height: 40px; */
    display: grid;
    grid-template-rows: repeat(2,20px);
    align-content: center;
    justify-content: center;    

}

.additionalData {
    width: 100%;
    height: 100%;
    font-size: 15px;
}

.widgetValues {
    width: 100%;
    height: 100%;
    font-weight: bold;
}

.widgetContainer2 {
    display: flex;
    flex-direction: row;
    margin-top: 7px;
    margin-bottom: 10px;
    gap: 20px;
    font-family: Arial, Helvetica, sans-serif;
 }
.widgetSubheader{
    width: 110px;
    height: 20px;
    text-align: center;
 }
.columnDivider {
    border-right: 2px solid rgb(223, 220, 220);
    height: 20px;
    margin-right: -10px; 
    padding-right: 10px;
    display: inline-block;
}

.tessssssst {
    display: flex; 
    align-items: center;
    justify-content: center;
  }
  
 .widgetContainer2.columnDivider:last-child {
    border-right: none;
 } 

 .widgetSingleValue {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
 }

 .record2Value {
    color: red;
    font-weight: normal;
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
 }

 