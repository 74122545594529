.chargeDescriptionPageContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 80px;
    align-items: center;
  }

  .midChargeDescription {
    display: flex;
    flex-direction: column;
    height: 70vh;
    width: 95%;
    background-color: #F5F5F5;
    -webkit-box-shadow: 0px 0px 5px 1px #BABABA; 
    box-shadow: 0px 0px 2px 1px #BABABA;
  }

.chargeDescriptionReport {
  flex: 9; 
  overflow-y: auto; 
}

.chargeDescriptionFilter {
  flex: 1; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chargeDescriptionCarrier {
  flex: 2;
  padding-left: 30px;
}

.chargeDescriptionSearch {
  flex: 5;
  padding-left: 10px;
}

.chargeDescriptionSubmit {
  flex: 1;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.bottomChargeDescription {
  width: 95%;
  display: flex;
  padding-top: 10px;
  justify-content: right;
}