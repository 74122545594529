.filter-panel {
    position: absolute;
    transform: translate(-80%, -65%); 
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 250px;
    max-height: 250px; /* Set a max-height to prevent growth */
    overflow-y: auto;
  }

  .filter-field {
    padding-bottom: 7px;
  }
  
  .filter-submit{
    display: flex;
    align-items: center;
    justify-content: center;
  }