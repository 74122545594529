.trackingLandingpageContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  padding-top: 80px;
}

.sidebar {
  z-index: 1;
}

.navbar {
  z-index: 2;
}