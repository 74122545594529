.serviceStatsPageContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 80px;
    align-items: center;
  }

  .reportSpace {
    display: flex;
    flex-direction: column;
    height: 80vh;
  }

  .serviceStatsReport {
    display: flex;
    flex-direction: column;
    height: 80%;
    -webkit-box-shadow: 0px 0px 5px 1px #BABABA; 
    box-shadow: 0px 0px 2px 1px #BABABA;
  }

  .downloadStatsReport {
    display: flex;
    padding-top: 10px;
    justify-content: right;
  }