.BiovationsDomesticPageContent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  padding-top: 80px;
}

.BiovationsDomesticForm {
  border: 1px solid gray;
  padding: 20px;
}

.BivoationDomesticLogo {
  position: absolute;
  top: 0;
  left: 0;
}

.BiovationsDomesticFormTop {
  position: relative;
  display: flex;
  flex-direction: column;
}

.Biovationsformsubtitle {
  display: flex;
  justify-content: center;
}

.BiovationFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BiovationFormFields {
  width: 33%;
}

.BiovationsButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  }

.BiovationDomField {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.MuiTextField-root {
  width: 100%;
}

.BiovationCol {
  display: flex;
  gap: 10px;
}

.BiovationStepFourTextFields {
  padding-top: 10px;
}

.BiovationDropBox {
  padding-bottom: 10px;
}

.BiovationFormFieldsStep2 {
  display: flex;
}

.BiovationColumn {
  flex: 1;
  padding: 10px;
}

