.auditFilterStartDate {
    padding: 5px
}

.auditFilterEndDate {
    padding: 5px;
}

.auditFilterSelectService {
    padding: 5px;
}

.auditFilterSubmit {
    display: flex;
    align-items: center;
    justify-content: center;
}