.ReportTitle {
    display: flex;
    flex-direction: row;
    font-family: sans-serif;
    justify-content: center;
  }
  
  .reportDescription {
    padding-left: 0.5%;
    align-self: center;
    display: flex;
    flex-direction: row;
  }
  
  /* Initially, hide the tooltip text */
  .hoverText {
    visibility: hidden; /* Hide by default */
    opacity: 0;          /* Initially hidden */
    position: absolute;
    margin-top: 2%;
    transform: translateX(-50%); /* Center the tooltip horizontally */
    background-color: black;
    color: white;
    padding: 1%;
    border-radius: 5px;
    transition: opacity 0.3s ease; /* Smooth transition for the tooltip */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Show the tooltip when hovering over the parent div */
  .reportDescription:hover .hoverText {
    visibility: visible; /* Make it visible */
    opacity: .75;          /* Fade in the tooltip */
  }