.gradientLegend {
  height: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: center;
  padding: 10% 10%;
}

.gradientLegendColor {
    flex-grow: 4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items:end;
    border-bottom: 2px solid black;
  }
  
.gradientLegendColorBlock {
  width: 10%;
  height: 70%;
}

.gradientLegendValueRange {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  font-size: .65vw;
  justify-content: center;
  align-content: center;
}

.gradientLegendMin{
  flex-grow: 1;
  text-align: left;
  align-content: center;
}

.gradientLegendMax{
  flex-grow: 1;
  text-align: right;
  align-content: center;
}