  .homepageContainer {
    display: flex;
    height: 100%;
  }
  
  .homepageContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 80px;
  }

  .homepageMid {
    display: flex;
    flex-direction: column;
  }

  .widgetContainer {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 20px;
  }

  .widgetBox {
    flex: 1;
  }

  .badgeBox {
    border: 1px solid #ccc;
  }

  .flexContainer {
    display: flex;
    flex-wrap: wrap; 
    justify-content: flex-start;
  }

  .chartBox {
    width: calc(50% - 20px);
    margin: 5px; 
    border: 1px solid #ccc;
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
  }