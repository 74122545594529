  .AccountInformationPageContent {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      flex-grow: 1;
      padding-top: 80px;
      justify-content: center;
      align-items: center;
      font-family: Arial, Helvetica, sans-serif;
  }

  .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

  .accountInformationField {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .accountInformationSelect {
    grid-column: 1;
    justify-self: center;
  }
  
  .accountInformationUpdate {
    grid-column: 2;
    margin-top: 10px;
    align-self: center; 
  }

  .dottedLineBreak {
    width: 100%;
    height: 1px;
    border-bottom: 1px dotted #000;
    margin: 10px 0;
  }

  .accountInformationSelectBox {
    width: 200px;
  }