.reweighPageContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 80px;
    align-items: center;
  }

  .topReweigh {
    text-align: center;
  }

  .topReweigh p{
    font-style: italic;
  }

  .midReweigh {
    display: flex;
    flex-direction: column;
    height: 70vh;
    width: 95%;
    background-color: #F5F5F5;
    -webkit-box-shadow: 0px 0px 5px 1px #BABABA; 
    box-shadow: 0px 0px 2px 1px #BABABA;
  }

.reweighReport {
  flex: 9; 
  overflow-y: auto; 

}

.reweighPageIndex{
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 5px;
  margin: 20px 10px 10px 10px;
}
.reweighPageIndexBtn{
  background-color: auto;
  border: 0px;
  color:#1976D2;
  padding: 5px;
  border-radius: 5px;
  font-size: large;
}

.reweighPageIndexBtn:disabled{
  color: #BABABA;
}

.reweighPageIndexBtn:disabled:hover{
  color: #BABABA;
  background-color: inherit;
  cursor: not-allowed;
}

.reweighPageIndexBtn:hover{
  cursor: pointer;
  background-color: #1976D2;
  color: white;
}

.reweighCurrentFilterList {
  display: flex;
  flex-direction: row;
  padding: 5px 20px;
  align-items: center;
  flex-wrap: wrap;
}

.reweighCurrentFilterItem {
  margin: 5px;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #1976D2;
  color: #1976D2;
  font-family: Arial, Helvetica, sans-serif;
}

.reweighCurrentFilterButton{
  display: flex;
  flex-direction: row;
  padding: 5px 20px;
  margin-bottom: 5px;
}

.reweighRemoveFilter {
  margin: 2px;
  color:rgb(184, 174, 174)
}
.reweighRemoveFilter:hover{
  cursor: pointer;
  color: #1976D2;
}

.reweighClearAll {
  margin: 0px 5px 5px 5px;
}

.reweighFilter {
  flex: 1; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.reweighCarrier {
  flex: 2;
  padding-left: 30px;
}

.reweighSearch {
  flex: 5;
  padding-left: 10px;
}

.reweighAddFilter {
  background-color: #1976D2;
  border-radius: 20px;
  margin: 0px 5px 0px 15px;
  padding: 2.5px 10px;
  font-size: x-large;
  color: white;
  font-weight:bolder;
  box-shadow:#BABABA;
}

.reweighAddFilter:hover {
  background-color: #1a70c6;
  cursor: pointer;
}

.reweighSubmit {
  flex: 1;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.disabledSumbit:hover {
  cursor: not-allowed;
}

.bottomReweigh {
  width: 95%;
  display: flex;
  padding-top: 10px;
  justify-content: right;
}