.costTrendsPageContent {
    padding: 80px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 80px;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.costTrendMid {
    width: 100%;
}

.ctReportSpace {
    display: flex;
    flex-direction: column;
    height: 75vh;
    width: 100%;
    -webkit-box-shadow: 0px 0px 5px 1px #BABABA; 
    box-shadow: 0px 0px 2px 1px #BABABA;
  }

.report{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}