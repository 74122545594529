.pieChartSummaryContainer{
    height: 100%;
    display: flex;
    flex-direction: row;
}

.half {
    flex: 1;
    align-items: center;
    height: 100%;
    overflow: hidden; 
}

.pieChartSummary {
    display: grid;
    grid-template-rows: repeat(auto-fill, minmax(60px, 1fr)); /* Create rows dynamically */
    gap: 20px; /* Space between the boxes */
    padding: 20px;
    height: 100%; /* Ensure the grid takes up the full parent height */
  }

/*
.pieChartSummary {
    height: 100%;
    display: flex;
    flex-wrap: wrap; 
    flex-direction: column;
    gap: 20px;
    padding-right: 20px;
}
*/

.chartd {
    height: 100%;
}