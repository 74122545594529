.pieChartSubTitle h4 {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.pieChartValue {
    display: flex;
    align-items: center;
    justify-content: center;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100%; 
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
    background-color: #FCFCFC ;
  }