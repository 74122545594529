.unauthorizedPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 98vh;
    font-family: system-ui;
}

.unauthorizedPageContainer {
    display: flex;
    height: 100%;
  }
  
  .unauthorizedPageContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 80px;
  }