.LandingPageContainer {
    display: flex;
    height: 100%;
  }

  .LandingPageContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    padding-top: 80px;
  }

  .sidebar {
    z-index: 1;
  }

  .navbar {
    z-index: 2;
  }

  .LandingPageContentTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 10px 3px #BABABA; 
    box-shadow: 0px 0px 15px 1px #BABABA;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .remitSearch {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
  }

  .remitTitle {
    font-family: Arial, Helvetica, sans-serif;
  }

  .LandingPageContentMid {
    -webkit-box-shadow: 0px 0px 10px 3px #BABABA; 
    box-shadow: 0px 0px 15px 1px #BABABA;
    border-radius: 10px;
  }