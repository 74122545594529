.clickupConnectPageContent, .taskInformationPageContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 80px;
    align-items: center;
    width: 100%;
}

.clickupConnectMid, .taskInformationMid {
    width: 100%;
}

.reportSpaced {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 95%;
    -webkit-box-shadow: 0px 0px 5px 1px #BABABA; 
    box-shadow: 0px 0px 2px 1px #BABABA;
  }

.report{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.reportButtons {
    background-color: #F5F5F5;
    -webkit-box-shadow: 0px 0px 5px 1px #BABABA; 
    box-shadow: 0px 0px 2px 1px #BABABA;
}

.clickupConnectLi {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 10px;
}

.seeMore {
    display: flex;
    justify-content: flex-end;
}

.bodyClass {
    margin-right: 5%;
}

.handleHover:nth-child(odd) {
    background-color: #f0f0f0; 
}

.handleHover:nth-child(even) {
    background-color: #ffffff; 
}

.handleHover:hover {
    cursor: pointer;
    background-color: rgba(25, 118, 210, 0.4);
}

.compBorder {
    -webkit-box-shadow: 0px 0px 5px 1px #BABABA; 
    box-shadow: 0px 0px 2px 1px #BABABA;
    padding: 5px;
    border-radius: 10px;
    min-height: 200px;
}

.fieldsSection {
    width: 70%;
}

.fieldPadding {
    padding: 15px;
    width: 90%;
}

.taskComponentHolder {
    padding-top: 20px;
    display: flex;
}

.smallComponentHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.commentBoxes {
    margin-bottom: 25px;
    background-color: #eeececd3;
    border: .25px solid black;
    padding: 15px;
    border-radius: 5px;
}

.commentHolder {
    height: 700px; 
    overflow-y: auto; 
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 45px;
    margin-left: 15px;
    width: 100%;
    max-width: 500px;
}

.tdStyle {
    padding: 4px;
    text-align: center;
}

.tdStyle2 {
    display: flex;
    text-align: center;
    padding: 10px;
    gap: 20px
}

.boxShadow {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.commentHeader {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
  }

  .commentsContainer {
    display: flex;
    flex-direction: column;
    height: 610px; 
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .commentsList {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 8px;
    padding: 2px;
  }
  
  .commentSubmitBtn {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px 0;
  }

.commentsList::-webkit-scrollbar {
    width: 10px; 
}

.commentsList::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 4px;
    padding: 5px;
}

.commentsList::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 4px;
    border: 2px solid #f1f1f1;
}

.commentsList::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.poStatusReportSearch {
    display: flex;
    align-items: center; 
    gap: 10px; 
    flex-wrap: nowrap;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
}