.badgeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto; 
}

.badges {
    position: relative;
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
  }

.badgeImage {
    width: 75px;
    height: 75px;
}

.badgeText {
    position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); 
    color: white; 
    font-size: 13px; 
    text-align: center; 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 
  }

  .grayed-out {
    filter: grayscale(100%);
  }

  .badgeNote h5 {
    margin: 0;
    padding: 0;
  }